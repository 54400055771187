var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    {
      attrs: {
        wrap: "",
        "justify-start": "",
        "pa-2": "",
        "pa-sm-4": "",
        "pa-lg-8": "",
      },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "py-4": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "app-border-color",
                      attrs: { outlined: "", rounded: "lg" },
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: { wrap: "", "justify-start": "", "pa-4": "" },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", sm4: "", lg3: "" } },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("pages.wards.ward")) + " "
                                ),
                              ]),
                              _c("br"),
                              _vm.service.ward
                                ? _c("span", { staticClass: "title-3" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.service.ward.name) + " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", sm4: "", lg3: "" } },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.department")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "title-3" },
                                [
                                  _vm._l(
                                    _vm.service.departments,
                                    function (item, index) {
                                      return [
                                        _c("span", { key: index }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.department.name) +
                                              " "
                                          ),
                                        ]),
                                        index + 1 <
                                        _vm.service.departments.length
                                          ? _c(
                                              "span",
                                              { key: "dash-" + index },
                                              [_vm._v(" | ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs6: "",
                                sm4: "",
                                lg3: "",
                                "pt-4": "",
                                "pt-sm-0": "",
                              },
                            },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.case_no")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "title-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.service.case_no
                                        ? _vm.service.case_no
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs6: "",
                                sm4: "",
                                lg3: "",
                                "pt-4": "",
                                "pt-lg-0": "",
                              },
                            },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.start_date")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "title-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .$moment(_vm.service.start_date)
                                        .format("DD MMM YYYY")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs6: "",
                                sm4: "",
                                lg3: "",
                                "pt-4": "",
                                "pt-lg-0": "",
                              },
                            },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.end_date")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "title-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .$moment(_vm.service.end_date)
                                        .format("DD MMM YYYY")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs6: "",
                                sm4: "",
                                lg3: "",
                                "pt-4": "",
                                "pt-lg-0": "",
                              },
                            },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("form.qualification")) +
                                    " "
                                ),
                              ]),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "title-3" },
                                [
                                  _vm._l(
                                    _vm.service.qualifications,
                                    function (item, index) {
                                      return [
                                        _c("span", { key: index }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.qualification.name) +
                                              " "
                                          ),
                                        ]),
                                        index + 1 <
                                        _vm.service.qualifications.length
                                          ? _c(
                                              "span",
                                              { key: "dash-" + index },
                                              [_vm._v(" | ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs6: "",
                                sm4: "",
                                lg3: "",
                                "pt-4": "",
                                "pt-lg-0": "",
                              },
                            },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.language")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "title-3" },
                                [
                                  _vm._l(
                                    _vm.service.languages,
                                    function (item, index) {
                                      return [
                                        _c("span", { key: index }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getLanguage(
                                                  item.language.id
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        index + 1 < _vm.service.languages.length
                                          ? _c(
                                              "span",
                                              { key: "dash-" + index },
                                              [_vm._v(" | ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs6: "",
                                sm4: "",
                                lg3: "",
                                "pt-4": "",
                                "pt-lg-0": "",
                              },
                            },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.gender")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c(
                                "span",
                                { staticClass: "title-3" },
                                [
                                  _vm._l(
                                    _vm.service.genders,
                                    function (item, index) {
                                      return [
                                        _c("span", { key: index }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getGender(item.gender_id)
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        index + 1 < _vm.service.genders.length
                                          ? _c(
                                              "span",
                                              { key: "dash-" + index },
                                              [_vm._v(" | ")]
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "pt-4": "", "pt-lg-0": "" } },
                            [
                              _c("span", { staticClass: "subtitle-2" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("form.lettering")) + " "
                                ),
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "title-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.service.lettering
                                        ? _vm.service.lettering
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "pb-4": "" } },
            [_c("v-spacer")],
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _vm.list.length
            ? _c(
                "v-layout",
                { attrs: { wrap: "", "justify-start": "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "title-2", attrs: { xs12: "", "pb-4": "" } },
                    [_vm._v(" " + _vm._s(_vm.$t("pages.settings.shift")) + " ")]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("AppSimpleTable", {
                        attrs: {
                          headings: _vm.headings,
                          items: _vm.list,
                          pagination: _vm.pagination,
                          "empty-title": "pages.services.no_service_to_show",
                        },
                        on: {
                          paginate: (val) => (_vm.pagination.page = val),
                          delete: _vm.toggleDelete,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "action",
                              fn: function ({ data }) {
                                return [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        wrap: "",
                                        "justify-start": "",
                                        "py-2": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { "text-center": "" } },
                                        [
                                          data.item.status.key !== 4 &&
                                          data.item.status.key !== 3
                                            ? _c("img", {
                                                staticClass: "cursor-pointer",
                                                attrs: {
                                                  src: require("@/assets/icons/trash.svg"),
                                                  height: "20px",
                                                  alt: "delete",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleStatus(
                                                      data.item
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1417482076
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("DeleteConfirmation", {
        attrs: { item: _vm.selectedItem, title: "pages.services.delete_shift" },
        on: { cancel: _vm.toggleDelete, submit: _vm.confirmChangeShiftStatus },
        model: {
          value: _vm.showDelete,
          callback: function ($$v) {
            _vm.showDelete = $$v
          },
          expression: "showDelete",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }